<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
    <ykc-form-item label="商品名称" prop="skuName">
      <ykc-input maxlength="50" placeholder="输入商品名称" v-model="ruleForm.skuName"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="商品编号" prop="skuNo">
      <ykc-input maxlength="30" placeholder="输入商品编号" v-model="ruleForm.skuNo"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="商品类别" prop="skuType">
      <ykc-dropdown
        placeholder="请选择商品类别"
        :clearable="false"
        :data="skuTypeData"
        v-model="ruleForm.skuType"></ykc-dropdown>
    </ykc-form-item>
    <ykc-form-item label="初始库存" prop="stock" v-if="!ruleForm.id">
      <ykc-input maxlength="8" placeholder="输入初始库存" v-model="ruleForm.stock"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="商品简介" prop="skuDesc">
      <ykc-input
        maxlength="500"
        placeholder="输入商品简介"
        type="textarea"
        v-model="ruleForm.skuDesc"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="商品原兑换积分" prop="score" v-if="!ruleForm.id">
      <ykc-input maxlength="6" placeholder="输入兑换积分" v-model="ruleForm.score"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="商品现兑换积分" prop="scoreDiscount" v-if="!ruleForm.id">
      <ykc-input
        maxlength="6"
        placeholder="输入兑换积分"
        v-model="ruleForm.scoreDiscount"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="上架选择" prop="saleType" v-if="!ruleForm.id">
      <ykc-radio
        :data="[
          { id: '1', name: '立即上架' },
          { id: '2', name: '定时上架' },
        ]"
        v-model="ruleForm.saleType"
        @change="changeSaleType"></ykc-radio>
    </ykc-form-item>
    <ykc-form-item
      v-if="ruleForm.saleType === '2' && !ruleForm.id"
      label="上架时间"
      prop="saleDate">
      <ykc-date-picker
        v-model="ruleForm.saleDate"
        :format="'yyyy-MM-dd HH'"
        :limitDate="'limitBeforeTime'"
        :valueFormat="'yyyy-MM-dd HH'"
        :type="'datetime'"
        placeholder="请选择上架时间"
        :onlyHour="true"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item
      label="商品图片"
      prop="imgs"
      class=""
      bottomTip="温馨提示：只能上传jpg/jpeg/png文件">
      <div class="upload-img-box">
        <ykc-upload
          ref="pictureUrls1"
          @handleChange="uploadImgs1"
          @handleRemove="handleRemoveImgs1"></ykc-upload>
        <ykc-upload
          ref="pictureUrls2"
          @handleChange="uploadImgs2"
          @handleRemove="handleRemoveImgs2"></ykc-upload>
        <ykc-upload
          ref="pictureUrls3"
          @handleChange="uploadImgs3"
          @handleRemove="handleRemoveImgs3"></ykc-upload>
        <ykc-upload
          ref="pictureUrls4"
          @handleChange="uploadImgs4"
          @handleRemove="handleRemoveImgs4"></ykc-upload>
        <ykc-upload
          ref="pictureUrls5"
          @handleChange="uploadImgs5"
          @handleRemove="handleRemoveImgs5"></ykc-upload>
      </div>
    </ykc-form-item>
    <ykc-form-item label="详细介绍" prop="content">
      <ykc-editor
        ref="editor"
        :max="20000"
        :isValidate="true"
        :validateTip="'详细简介'"
        :validSpecialCharacter="false"
        :msgValidate="'请输入详细介绍'"></ykc-editor>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { pointsMall, uploadUtils } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
      },
    },
    data() {
      return {
        skuTypeData: [
          { id: '1', name: '车辆用品' },
          { id: '2', name: '服饰' },
          { id: '3', name: '文具' },
          { id: '4', name: '纪念品' },
          { id: '5', name: '其他' },
        ],
        ruleForm: {
          id: null,
          skuName: '',
          skuNo: '',
          skuType: '',
          stock: '',
          skuDesc: '',
          score: '',
          scoreDiscount: '',
          saleType: '1',
          saleDate: '',
          imgs: [], // 商品图片
          content: '',
        },
        rules: {
          // 校验规则
          skuName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('商品名称'),
            },
            { validator: this.skuNamelyCheck, trigger: 'blur' },
          ],
          skuNo: [
            { required: true, message: '请输入商品编号', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType_num.regexp,
              message: regexpObj.regexp.input.inlineTextType_num.errorTips.error('商品编号'),
            },
            { validator: this.skuNolyCheck, trigger: 'blur' },
          ],
          skuType: [{ required: true, message: '请选择商品类别', trigger: 'blur' }],
          stock: [
            { required: true, message: '请输入初始库存', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: '请输入正确的初始库存(范围，整数，1~99999999）',
              trigger: 'blur',
            },
          ],
          skuDesc: [
            { required: true, message: '请输入商品简介', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('商品简介'),
            },
          ],
          score: [
            { required: true, message: '请输入商品原兑换积分', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: '请输入正确的商品原兑换积分（范围，整数，1~999999）',
              trigger: 'blur',
            },
            { validator: this.displayPriceCheck, trigger: 'blur' },
          ],
          scoreDiscount: [
            { required: true, message: '请输入商品现兑换积分', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: '请输入正确的商品现兑换积分（范围，整数，1~999999）',
              trigger: 'blur',
            },
            { validator: this.priceCheck, trigger: 'blur' },
          ],
          saleType: [{ required: true, message: '请选择上架类型', trigger: 'change' }],
          saleDate: [{ required: true, trigger: 'change', message: '请选择上架时间' }],
          imgs: [{ required: true, message: '请上传图片', trigger: 'change' }],
          content: [{ required: true, validator: this.skuContentCheck, trigger: 'blur' }],
        },
      };
    },
    created() {
      if (this.isEdit) {
        console.log('bianji', this.id, this.isEdit);
        pointsMall.skuDetail({ id: this.id }).then(res => {
          console.log('detail', res);
          this.ruleForm = { ...res };
          if (res.content) {
            this.$refs.editor.setHtml(res.content);
          }
          if (res.imgs.length !== 0) {
            if (res.imgs.length === 1) {
              this.$refs.pictureUrls1.fileList = [{ url: res.imgs[0] }];
            } else if (res.imgs.length === 2) {
              this.$refs.pictureUrls1.fileList = [{ url: res.imgs[0] }];
              this.$refs.pictureUrls2.fileList = [{ url: res.imgs[1] }];
            } else if (res.imgs.length === 3) {
              this.$refs.pictureUrls1.fileList = [{ url: res.imgs[0] }];
              this.$refs.pictureUrls2.fileList = [{ url: res.imgs[1] }];
              this.$refs.pictureUrls3.fileList = [{ url: res.imgs[2] }];
            } else if (res.imgs.length === 4) {
              this.$refs.pictureUrls1.fileList = [{ url: res.imgs[0] }];
              this.$refs.pictureUrls2.fileList = [{ url: res.imgs[1] }];
              this.$refs.pictureUrls3.fileList = [{ url: res.imgs[2] }];
              this.$refs.pictureUrls4.fileList = [{ url: res.imgs[3] }];
            } else if (res.imgs.length === 5) {
              this.$refs.pictureUrls1.fileList = [{ url: res.imgs[0] }];
              this.$refs.pictureUrls2.fileList = [{ url: res.imgs[1] }];
              this.$refs.pictureUrls3.fileList = [{ url: res.imgs[2] }];
              this.$refs.pictureUrls4.fileList = [{ url: res.imgs[3] }];
              this.$refs.pictureUrls5.fileList = [{ url: res.imgs[4] }];
            }
          }
        });
      }
    },
    computed: {
      formData() {
        const formData = {
          id: this.ruleForm.id,
          skuName: this.ruleForm.skuName,
          skuNo: this.ruleForm.skuNo,
          skuType: this.ruleForm.skuType,
          stock: this.ruleForm.stock,
          skuDesc: this.ruleForm.skuDesc,
          score: this.ruleForm.score,
          scoreDiscount: this.ruleForm.scoreDiscount,
          saleType: this.ruleForm.saleType,
          saleDate: this.ruleForm.saleDate,
          imgs: this.ruleForm.imgs,
          content: this.ruleForm.content,
        };
        return formData;
      },
    },
    methods: {
      changeSaleType(e) {
        console.log(JSON.stringify(e));
      },
      uploadImgs1(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.imgs.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      uploadImgs2(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.imgs.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      uploadImgs3(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.imgs.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      uploadImgs4(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.imgs.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      uploadImgs5(file, fileList, isCheck) {
        if (isCheck) {
          const formData = new FormData();
          const param = {
            file: file.raw,
            bizName: '',
          };
          // 处理入参格式
          Object.entries(param).forEach(([k, v]) => {
            formData.append(k, v);
          });
          uploadUtils.uploadImg(formData).then(res => {
            this.ruleForm.imgs.push(res);
            console.log('res+++++图片', this.ruleForm.imgs);
          });
        }
      },
      /**
       * 图片删除
       */
      handleRemoveImgs1(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.imgs.splice(0, 1);
      },
      handleRemoveImgs2(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.imgs.splice(1, 1);
      },
      handleRemoveImgs3(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.imgs.splice(2, 1);
      },
      handleRemoveImgs4(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.imgs.splice(3, 1);
      },
      handleRemoveImgs5(file, fileList) {
        console.log(file, fileList);
        this.ruleForm.imgs.splice(4, 1);
      },
      /**
       * 商品原兑换积分
       */
      displayPriceCheck(rule, value, callback) {
        const price = this.ruleForm.scoreDiscount;
        if (price && parseInt(value, 10) < parseInt(price, 10)) {
          callback(
            new Error('请输入正确的商品原兑换积分（范围，整数，现兑换积分≤原兑换积分≤99999999）')
          );
        } else {
          callback();
        }
      },
      /**
       * 商品现兑换积分
       */
      priceCheck(rule, value, callback) {
        const displayPrice = this.ruleForm.score;
        if (displayPrice && parseInt(value, 10) > parseInt(displayPrice, 10)) {
          callback(
            new Error('请请输入正确的商品现兑换积分（范围，整数，1≤现兑换积分≤原兑换积分）')
          );
        } else {
          callback();
        }
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /**
       * 商品名称唯一性验证
       */
      skuNamelyCheck(rule, value, callback) {
        if (value) {
          if (this.isEdit) {
            pointsMall
              .checkUniqueSkuName({ id: this.id, skuName: value })
              .then(res => {
                console.log(JSON.stringify(res));
                if (res && this.ruleForm.skuName !== this.skuName) {
                  callback(new Error('名称重复'));
                } else {
                  callback();
                }
              })
              .catch(e => {
                console.log(JSON.stringify(e));
                callback();
              });
          } else {
            pointsMall
              .checkUniqueSkuName({ skuName: value })
              .then(res => {
                console.log(JSON.stringify(res));
                if (res && this.ruleForm.skuName !== this.skuName) {
                  callback(new Error('名称重复'));
                } else {
                  callback();
                }
              })
              .catch(e => {
                console.log(JSON.stringify(e));
                callback();
              });
          }
        }
      },
      /**
       * 商品编号唯一性验证
       */
      skuNolyCheck(rule, value, callback) {
        if (value) {
          if (this.isEdit) {
            pointsMall
              .checkUniqueSkuNo({ id: this.id, skuNo: value })
              .then(res => {
                console.log(JSON.stringify(res));
                if (res && this.ruleForm.skuNo !== this.skuNo) {
                  callback(new Error('编号重复'));
                } else {
                  callback();
                }
              })
              .catch(e => {
                console.log(JSON.stringify(e));
                callback();
              });
          } else {
            pointsMall
              .checkUniqueSkuNo({ skuNo: value })
              .then(res => {
                console.log(JSON.stringify(res));
                if (res && this.ruleForm.skuNo !== this.skuNo) {
                  callback(new Error('编号重复'));
                } else {
                  callback();
                }
              })
              .catch(e => {
                console.log(JSON.stringify(e));
                callback();
              });
          }
        }
      },
      skuContentCheck(rule, value, callback) {
        const editorHtml = this.$refs.editor.getHtml();
        if (editorHtml instanceof Error) {
          callback(editorHtml.message);
        }
        this.ruleForm.content = editorHtml;
        callback();
      },
      /**
       * 新增保存
       */
      submitForm() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (this.isEdit) {
              console.log('编辑this.ruleForm', this.ruleForm);
              pointsMall.edit(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            } else {
              console.log('新增this.ruleForm', this.ruleForm);
              pointsMall.add(this.formData).then(() => {
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              });
            }
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .upload-img-box {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep .el-form-item__content {
    display: flex;
    flex-flow: column nowrap;
  }

  .ykc-form .ykc-form-item .el-form-item .el-form-item__content .ykc-dropdown {
    width: 100%;
  }
</style>
