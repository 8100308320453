<!--
 * @ Author:  
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 16:48:31
 * @ Description: 营销中心>平台营销>积分商城>商品管理   
 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="商品列表"
        operateFixedType="right"
        :selectable="false"
        :selectOnIndeterminate="false"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'marketing:integral:good:add'" @click="add">
              新增
            </ykc-button>
            <ykc-button
              type="plain"
              v-rbac="'marketing:integral:good:export'"
              @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <ykc-drawer
        :show.sync="showDrawer"
        :title="drawerTitle"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel"
        ensure-txt="保存">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            ref="addOrEdit"
            v-if="drawerType === 'edit'"
            :active="stepsIndex"
            :isEdit="isEdit"
            :id="id" />
        </div>
      </ykc-drawer>
    </div>
    <ykc-operation-dialog
      title="定价"
      :on-close="onCloseSetPrice"
      :show.sync="setPriceDialog"
      :before-cancel="beforeCancelSetPrice"
      :before-confirm="beforeConfirmSetPrice">
      <div slot="content">
        <ykc-form :model="setPriceForm" :rules="setPriceRules" ref="setPriceForm">
          <ykc-form-item label="商品名称：">{{ setPriceForm.skuName }}</ykc-form-item>
          <ykc-form-item label="商品编号：">{{ setPriceForm.skuNo }}</ykc-form-item>
          <ykc-form-item label="商品类别：">{{ setPriceForm.skuTypeMean }}</ykc-form-item>
          <ykc-form-item label="标价" prop="score">
            <ykc-input
              placeholder="输入标价"
              maxlength="8"
              v-model="setPriceForm.score"></ykc-input>
          </ykc-form-item>
          <ykc-form-item label="现价" prop="scoreDiscount">
            <ykc-input
              placeholder="输入现价"
              maxlength="8"
              v-model="setPriceForm.scoreDiscount"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </ykc-operation-dialog>
    <ykc-operation-dialog
      title="库存"
      :on-close="onCloseStock"
      :show.sync="setStockDialog"
      :before-cancel="beforeCancelStock"
      :before-confirm="beforeConfirmStock">
      <div slot="content">
        <ykc-form :model="setStockForm" :rules="setStockRules" ref="setStockForm">
          <ykc-form-item label="商品名称：">{{ setStockForm.skuName }}</ykc-form-item>
          <ykc-form-item label="商品编号：">{{ setStockForm.skuNo }}</ykc-form-item>
          <ykc-form-item label="商品类别：">{{ setStockForm.skuTypeMean }}</ykc-form-item>
          <ykc-form-item label="总库存：">{{ setStockForm.stock }}</ykc-form-item>
          <ykc-form-item label="剩余库存：">{{ setStockForm.saleStock }}</ykc-form-item>
          <ykc-form-item label="增减总库存：" prop="setStock">
            <ykc-input
              maxlength="8"
              placeholder="输入增减总库存"
              v-model="setStockForm.setStock"></ykc-input>
          </ykc-form-item>
        </ykc-form>
      </div>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import AddOrEdit from './AddOrEdit.vue';
  import YkcDialog from '@/components/ykc-ui/dialog';

  import regexpObj from '@/utils/regexp';
  import { pointsMall } from '@/service/apis';
  import { code, offlineExport } from '@/utils';

  export default {
    name: 'commodityList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: null,
        dictionary: {
          skuType: [
            { id: '1', name: '车辆用品' },
            { id: '2', name: '服饰' },
            { id: '3', name: '文具' },
            { id: '4', name: '纪念品' },
            { id: '5', name: '其他' },
          ],
          status: [
            { id: '1', name: '待上架' },
            { id: '2', name: '已上架' },
            { id: '3', name: '已下架' },
          ],
        },
        isEdit: false,
        drawerType: '',
        showDrawer: false,
        stepsIndex: 0,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => true,
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.stepsIndex = 0;
                this.showDrawer = false;
                this.searchTableList();
              });
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '商品编号', prop: 'skuNo', minWidth: '200px' },
          { label: '商品名称', prop: 'skuName', minWidth: '240px' },
          { label: '商品类别', prop: 'skuTypeMean', minWidth: '130px' },
          { label: '原兑换积分', prop: 'score', minWidth: '130px' },
          { label: '折扣兑换积分', prop: 'scoreDiscount', minWidth: '130px' },
          { label: '总数量', prop: 'stock', minWidth: '130px' },
          { label: '剩余库存', prop: 'saleStock', minWidth: '130px' },
          { label: '商品状态', prop: 'statusMean', minWidth: '100px' },
          { label: '修改人', prop: 'modifiedName', minWidth: '130px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '150px' },
        ],

        tableOperateButtons: [
          {
            enabled: () => code('marketing:integral:good:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.doEdit(row);
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:good:under'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  YkcDialog({
                    showTitle: false,
                    showFooter: true,
                    dialogType: 'feedback',
                    desc: `是否确认${row.status === '2' ? '下架' : '上架'}？`,
                    onCancel: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                    },
                    onConfirm: dialogDone => {
                      dialogDone(); // 隐藏弹窗
                      this.putOnOrOffShelf(row);
                      this.showDrawer = false; // 隐藏抽屉
                    },
                  });
                }}>
                {row.status === '2' ? '下架' : '上架'}
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:good:price'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.setPrice(row);
                }}>
                定价
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:good:stock'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.setStock(row);
                }}>
                库存
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:integral:good:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.detailLink(row);
            },
          },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        searchParams: {
          skuName: '',
          skuNo: '',
          skuType: '',
          status: '',
        },
        setPriceDialog: false, // 定价  处理弹窗
        setPriceForm: {
          skuName: '',
          skuNo: '',
          skuTypeMean: '',
          score: '',
          scoreDiscount: '',
        },
        setPriceRules: {
          score: [
            { required: true, message: '请输入标价', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: '请输入正确的商品标价（范围，整数，1~99999999）',
              trigger: 'blur',
            },
            { validator: this.displayPriceCheck, trigger: 'blur' },
          ],
          scoreDiscount: [
            { required: true, message: '请输入现价', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.integerNumberType.regexp,
              message: '请请输入正确的商品现价（范围，整数，1≤商品现价≤商品标价）',
              trigger: 'blur',
            },
            { validator: this.priceCheck, trigger: 'blur' },
          ],
        },
        setStockDialog: false, // 库存  弹窗控制
        setStockForm: {
          setStock: '',
        },
        setStockRules: {
          setStock: [
            { required: true, message: '请输入增减总库存', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.plusMinusNumberType.regexp,
              message: regexpObj.regexp.input.plusMinusNumberType.errorTips.error(
                '增减总库存',
                999999
              ),
            },
            { validator: this.verifyUpdateStock, trigger: 'blur' },
          ],
        },
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        return `${this.isEdit ? '编辑' : '新增'}商品`;
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'skuName',
            label: '商品名称',
            placeholder: '请输入商品名称',
          },
          {
            comName: 'YkcInput',
            key: 'skuNo',
            label: '商品编号',
            placeholder: '请输入商品编号',
          },
          {
            comName: 'YkcDropdown',
            key: 'skuType',
            label: '商品类别',
            data: this.dictionary.skuType,
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '商品状态',
            data: this.dictionary.status,
          },
        ];
      },
    },
    methods: {
      /* 编辑 */
      doEdit(row) {
        console.log('编辑', row);
        this.id = row.id;
        this.isEdit = true;
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /* 定价  取消 */
      beforeCancelSetPrice(done) {
        this.$refs.setPriceForm.clearValidate();
        done();
      },
      /* 定价弹窗  确定 */
      beforeConfirmSetPrice() {
        return new Promise(resolve => {
          this.validateForm().then(() => {
            const reqParams = {
              id: this.setPriceForm.id,
              score: this.setPriceForm.score,
              scoreDiscount: this.setPriceForm.scoreDiscount,
            };
            console.log(reqParams);
            pointsMall.price(reqParams).then(() => {
              this.setPriceDialog = false;
              this.searchTableList();
              this.$message({
                message: '定价设置成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      /* 定价弹窗 关闭 */
      onCloseSetPrice() {
        this.$refs.setPriceForm.clearValidate();
        this.setPriceDialog = false;
      },
      /**
       * 标价校验
       */
      displayPriceCheck(rule, value, callback) {
        const price = this.setPriceForm.scoreDiscount;
        if (price && parseInt(value, 10) < parseInt(price, 10)) {
          callback(new Error('请输入正确的商品标价（范围，整数，商品现价≤商品标价≤99999999）'));
        } else {
          callback();
        }
      },
      /**
       * 现价校验
       */
      priceCheck(rule, value, callback) {
        const displayPrice = this.setPriceForm.score;
        if (displayPrice && parseInt(value, 10) > parseInt(displayPrice, 10)) {
          callback(new Error('请请输入正确的商品现价（范围，整数，1≤商品现价≤商品标价）'));
        } else {
          callback();
        }
      },
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.setPriceForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /* 定价按钮 */
      setPrice(row) {
        console.log('定价', row);
        this.setPriceDialog = true;
        this.setPriceForm.skuName = row.skuName;
        this.setPriceForm.skuNo = row.skuNo;
        this.setPriceForm.skuTypeMean = row.skuTypeMean;
        this.setPriceForm.score = row.score;
        this.setPriceForm.scoreDiscount = row.scoreDiscount;
        this.setPriceForm.id = row.id;
      },

      /* 新增 */
      add() {
        console.log('新增');
        this.isEdit = false;
        this.id = '';
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /* 上下架 */
      putOnOrOffShelf(row) {
        const reqParams = {
          id: row.id,
          status: row.status === '2' ? '3' : '2',
        };
        pointsMall.status(reqParams).then(res => {
          console.log('res', res);
          this.$message({
            message: reqParams.status === '3' ? '下架成功' : '上架成功',
            type: 'success',
          });
          this.searchTableList();
        });
      },

      /* 库存弹窗 关闭 */
      onCloseStock() {
        this.$refs.setStockForm.clearValidate();
        this.setStockDialog = false;
      },
      /* 库存  取消 */
      beforeCancelStock(done) {
        this.$refs.setStockForm.clearValidate();
        done();
      },
      /* 库存  确定 */
      beforeConfirmStock() {
        return new Promise(resolve => {
          this.validateForm1().then(() => {
            const reqParams = {
              id: this.setStockForm.id,
              stock: this.setStockForm.setStock,
            };
            console.log(reqParams);
            pointsMall.stock(reqParams).then(() => {
              this.setStockDialog = false;
              this.searchTableList();
              this.$message({
                message: '库存设置成功',
                type: 'success',
              });
              resolve();
            });
          });
        });
      },
      validateForm1() {
        return new Promise((resolve, reject) => {
          this.$refs.setStockForm.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      /* 库存范围校验 */
      verifyUpdateStock(rule, value, callback) {
        console.log('value+++', value);
        const absVal = Math.abs(value);
        if (value > 0) {
          callback();
        } else if (absVal <= this.setStockForm.saleStock) {
          callback();
        } else {
          callback(new Error('减总库存数不能大于剩余库存'));
        }
      },
      /* 库存按钮 */
      setStock(row) {
        console.log('库存', row);
        this.setStockDialog = true;
        this.setStockForm.skuName = row.skuName;
        this.setStockForm.skuNo = row.skuNo;
        this.setStockForm.stock = row.stock;
        this.setStockForm.skuTypeMean = row.skuTypeMean;
        this.setStockForm.saleStock = row.saleStock;
        this.setStockForm.setStock = row.setStock;
        this.setStockForm.id = row.id;
      },
      /* 详情按钮 */
      detailLink(row) {
        this.$router.push({
          path: '/marketingCenter/platformMarketingTools/pointsMall/commodity/details',
          query: {
            id: row.id,
          },
        });
      },

      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          pointsMall.skuPage({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 导出文件 - 商品列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'mall_sku_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },

      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
